<template>
  <router-view/>
</template>
<style lang="stylus">
#app
  height 100%
  width 100%
</style>
<script setup>
import store from '@/store'
import { computed, onMounted, watch, unref } from 'vue'
store.dispatch('getInMiniapp')

</script>
