import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bookDirective from '@/utils/book/directive'
import "./permission.js"
import fastClick from 'fastclick'

import '@/styles/index.styl'

fastClick.attach(document.body)

const app = createApp(App)
Object.keys(bookDirective).forEach(d => app.directive(d, bookDirective[d]))
app
.use(store)
.use(router)
.mount('#app')
