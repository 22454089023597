import { createStore } from 'vuex'
export default createStore({
  state: {
    inMiniapp:false,
    indexLoading:true,
  },
  getters: {
    inMiniapp:state=>state.inMiniapp,
    indexLoading:state=>state.inMiniapp
  },
  mutations: {
    setInMiniapp:(state,data)=>{
      state.inMiniapp = data
    },
    setIndexLoading:(state,data)=>{
      state.indexLoading = data
      let isShow = state.indexLoading
      console.log('toggleIndexLoadingShow',isShow)
      let loadingDom = document.getElementById('YXIndexLoading')
      if(isShow){
        loadingDom.style.display = 'block'
      }else{
        loadingDom.style.display = 'none'
      }
    }
  },
  actions: {
    getInMiniapp:({commit})=>{
      let userAgent = navigator.userAgent
      if(userAgent&&userAgent.indexOf('miniProgram')>-1){
        commit('setInMiniapp',true)
      }else{
        commit('setInMiniapp',false)
      }
    }
  },
  modules: {
  }
})
