import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })
router.beforeEach((to,from,next)=>{
    store.commit('setIndexLoading',true)
    NProgress.start()
    next()
})
router.afterEach((to,from,next)=>{
    store.commit('setIndexLoading',false)
    document.title = to?.meta?.title||'项目介绍'
    NProgress.done()
})