import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/book',
    name: 'book',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/book/index.vue'),
    children:[
      {
        path:'haining',
        name:'haining-book',
        meta:{
          title:'杭州湾智慧医疗产业园'
        },
        component:()=>import('../views/book/haining/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
