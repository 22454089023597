import common from '@/utils/book/common'
const imgView = {
    mounted:(el,binding)=>{
        const imgsDom = el.querySelectorAll('img[view]')
        const images = []
        imgsDom.forEach(item=>{
            images.push(item.src)
        })
        el.__imgView__ = (e)=>{
            var curImg = e.target.src||e.src
            if(curImg&&images.indexOf(curImg)>-1){
                common.$previewImages(images,curImg)
            }
        }
        el.addEventListener('click',el.__imgView__,true)
    },
    unmounted:(el)=>{
        el.removeEventListener('click',el.__imgView__,true)
    }
}

const textErase = {
    mounted:(el,binding)=>{
        const {delay,backgroundColor} = binding.value||{}
    }
}

const directives = {
    imgView,
}
export default directives