
import { showImagePreview } from 'vant'
import 'vant/es/image-preview/style'

const $previewImages = function(data,item,key){
    let images = []
    let startPosition = 0
    if(key){
        data.forEach((item,index)=>{
            images.push(item[key])
        })
    }else{
        data.forEach((item,index)=>{
            images.push(item)
        })
    }
    if(item){
        startPosition = data.findIndex(d=>{
            return d==item
        })
    }
    showImagePreview({
        images:images,
        startPosition:startPosition>0?startPosition:0,
        loop:false,
        // closeOnPopstate:true
    })
}
export default {
    $previewImages
}